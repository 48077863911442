import ninjaApi from 'store/redux/apis/ninja.api';

export const ninjaTableApi = ninjaApi.injectEndpoints({
  tagTypes: ['NinjaTable'],
  overrideExisting: true,
  endpoints: (build) => ({
    customTableList: build.query({
      query: (body) => ({
        url: '/custom-tables/list',
        method: 'POST',
        body,
      }),
      providesTags: ['NinjaTable'],
    }),

    createTablesList: build.mutation({
      query: (body) => ({
        url: '/custom-tables/create',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['NinjaTable'],
    }),
  }),
});

export const { useCustomTableListQuery, useCreateTablesListMutation } = ninjaTableApi;
