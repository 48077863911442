import { Button, Card, Popover, Segmented, Select, Space } from 'antd';
import React, { createContext, useContext, useState } from 'react';
import './widget.scss';
import Icon from '@mdi/react';
import { mdiArrowAll, mdiFileTree, mdiFilterOutline } from '@mdi/js';
import WidgetFilter from './WidgetFilter';
import clsx from 'clsx';
import { useWidgetDate } from 'store/redux/states/dates/dates.hooks';
import NinjaTableCustomize from 'components/tables/NinjaTable/NinjaTableCustomize';

const WidgetContext = createContext({});

export const useWidget = () => useContext(WidgetContext);

const Widget = (props) => {
  const default_filter = { statuses: [], network_account_ids: [], network: null, limit: 10 };
  const [filters, setFilters] = useState(default_filter);
  const [level, setLevel] = useState('adset');
  const [runFilterTimestamp, setRunFilterTimestamp] = useState('adset');
  const {
    widget_key,
    title,
    tableWidget,
    disableLevelFilter,
    disableLimit,
    disableDateFilter,
    disableStatusFilter,
    disableAcountsFilter,
  } = props;

  const { date_from, date_to } = useWidgetDate(widget_key);

  if (!widget_key) return null;

  /** Update filter timestamp, table and query listens to it and then it is updated */
  const runFilter = () => {
    setRunFilterTimestamp(new Date().getTime());
  };

  /** Change reporting level */
  const changeLevel = (level) => {
    setLevel(level);
    // When reporting level is changed filter must run immidiatelly
    runFilter();

    props.onLevelChange?.(level);
  };

  const changeLimit = (value) => {
    setFilters((f) => {
      f.limit = value;
      return f;
    });
    runFilter();
  };

  const options = {
    adset: {
      name: 'Adsets',
      value: 'adset',
    },
    campaign: {
      name: 'Campaigns',
      value: 'campaign',
    },
    ad: {
      name: 'Ads',
      value: 'ad',
    },
  };

  return (
    <WidgetContext.Provider
      value={{
        widget_key,
        title,

        filters,
        setFilters,

        level,
        changeLevel,

        runFilterTimestamp,
        runFilter,

        default_filter,

        widget_filters: {
          ...filters,
          date_from,
          date_to,
          level,
        },
      }}
    >
      <Card
        title={title}
        extra={
          <Space>
            <Button
              icon={<Icon path={mdiArrowAll} />}
              loading={false}
              onClick={() => console.log('moving')}
            />
            <NinjaTableCustomize
              changeLimit={changeLimit}
              changeLevel={changeLevel}
              disableLevelFilter={disableLevelFilter}
              levelOption={options}
              defaultLevel={level}
              defaultPerPage={filters.limit}
              disableLimit={disableLimit}
              savedFilters={true}
              renderFilter={
                <WidgetFilter
                  widget_key={widget_key}
                  disableDateFilter={disableDateFilter}
                  disableStatusFilter={disableStatusFilter}
                  disableAcountsFilter={disableAcountsFilter}
                />
              }
            />
          </Space>
        }
        className={clsx('widget-card w-full', {
          'table-widget': tableWidget,
        })}
      >
        {props.children}
      </Card>
    </WidgetContext.Provider>
  );
};

export default Widget;
