import React, {
  createContext,
  Fragment,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
  useMemo,
} from 'react';
import NinjaTableFooter from './NinjaTableFooter';
import NinjaTableGrid from './NinjaTableGrid';
import NinjaTableWrapper from './NinjaTableWrapper';
import useColumns from './useColumns';
import ninjaApi from 'store/redux/apis/ninja.api';
import _isEqual from 'lodash/isEqual';
import clsx from 'clsx';
import { useWidgetDate } from 'store/redux/states/dates/dates.hooks';
import { useWidget } from 'components/Widget/Widget';
import { useLocation } from 'react-router-dom';
import FileDownload from 'js-file-download';
import axios from 'axios';
import { useCustomTableListQuery } from 'store/redux/apis/ninja-table.api';

export const NinjaTableContext = createContext({});

export const NinjaTable = (props) => {
  const {
    page = 1,
    per_page = 10,
    withoutHeader,
    dataProvider,
    queryParams = {},
    savedFiltersParams,
    sortableColumns = false,
    sortable,
    getComparator,
    header,
    datepicker,
    customizationId,
    exportData,
    customize,
    onCustomizationUpdate,
    customizationEdit,
    removeColumns,
    rowHeight,
    /* table props */
    columns: allColumns = [],
    tableProps = {
      template: 'outerreporting',
      dynamicHeight: true,
    },
    footerProps = {
      showPagination: true,
      showPerPage: true,
      totalDataNum: false,
    },
    disableCard,
    disableFooter = false,
    maxHeight = null,

    // datepicker props
    showExpandDays = false,
    date_key,

    /* table wrapper props, [table can be without wrapper or filters] */
    title,
    icon,
    wrapperProps = {},
    selectRow,

    /* table filter props */
    filterComponent: FilterComponent = Fragment,
    filterProps = {},
    selectorComponent: SelectorComponent = Fragment,
    selectorProps = {},
    enableFilters,
    id,

    /**
     * Whether to show loader on fetching
     */
    loadOnFetching = true,

    /* Other layout components */
    prependComponent,
    tablePrependComponent,
    wrapperPrependComponent,

    containerClassName,
    widget_key,
    emitPerPage = (val) => {},
    defaultFilters = {},
    ...rest
  } = props;

  /* Table State */

  // Saved filters
  const [savedFilters, setSavedFilters] = useState([]);
  const [activeFilters, setActiveFilters] = useState({});
  const [initialFilter, setInitialFilter] = useState({});
  const [selectedRows, setSelectedRows] = useState(new Set());
  const [statuses, setStatuses] = useState();
  const [localFilterText, setLocalFilterText] = useState('');
  const [extraQueryParams, setExtraQueryParams] = useState(queryParams);
  const [columns, setColumns] = useState([]);
  const [filters, _setFilters] = useState(defaultFilters);
  const [sortColumns, onSortColumnsChange] = useState([]);
  const [pagination, setPagination] = useState({
    page: page,
    per_page: per_page,
    total_pages: 1,
  });

  const [getCustomTableListQuery] = ninjaApi.useLazyCustomTableListQuery();

  useEffect(() => {
    const transformedFields = {};
    if (customizationId) {
      getCustomTableListQuery({ tableKey: customizationId })
        .unwrap()
        .then((data) => {
          data?.forEach((field) => {
            transformedFields[field.field] = {
              hidden: field.visible === 0,
              name: field.field,
            };
          });
          const newColumns = allColumns?.filter(
            (column) => !(transformedFields[column.key]?.hidden === true)
          );
          setColumns(newColumns);
        });
    } else {
      setColumns(allColumns);
    }
  }, [customizationId, customizationId]);

  const { dates: dates_redux } = useWidgetDate();
  const [dates, setDates] = useState(dates_redux);

  useEffect(() => {
    setDates(dates_redux);
  }, [dates_redux]);

  const location = useLocation();
  const qParams = new URLSearchParams(location.search);
  const onContacts = qParams.get('contacts') ? true : false;

  const columnProps = useColumns(columns, {
    sortable: sortableColumns,
    id,
  });

  const [refetch, result] = (
    ninjaApi.endpoints[dataProvider] ? ninjaApi.endpoints[dataProvider] : ninjaApi.endpoints.fake
  ).useLazyQuery();

  const _data = Array.isArray(result.data) ? result.data : result.data?.data || [];

  /**
   * Load widget data
   */
  const { widget_filters, runFilterTimestamp } = useWidget(widget_key);

  /**
   * Fetch Data
   * @returns {void}
   */
  const fetchData = () => {
    const data = {
      filters: { ...filters, by_unique_field: onContacts ? true : false },
      perPage: per_page,
      sort: sortColumns,
      ...dates,
      ...extraQueryParams,
      ...pagination,
      ...queryParams,
      ...widget_filters,
    };
    refetch(data)
      ?.unwrap()
      .then((res) => {
        if (res.export_file_url && extraQueryParams.export) {
          axios({
            url: res.export_file_url,
            method: 'GET',
            responseType: 'blob',
          }).then((response) => {
            FileDownload(response.data, 'reporting.xlsx');
          });
        }
      });
  };

  useEffect(() => {
    fetchData();
  }, [
    filters,
    onContacts,
    statuses,
    pagination.page,
    pagination.per_page,
    dates,
    sortColumns,
    extraQueryParams,
    runFilterTimestamp,
  ]);

  useLayoutEffect(() => {
    const pagination = result.data?.pagination;
    if (!pagination) {
      setPagination({
        page: page,
        per_page: per_page,
        total_pages: 1,
      });
    } else {
      setPagination({
        total_pages: Math.ceil(pagination.total / pagination.per_page),
        ...pagination,
      });
    }
  }, [result.data?.pagination]);

  useEffect(() => {
    const newQueryParams = { ...extraQueryParams, ...queryParams };
    if (_isEqual(newQueryParams, extraQueryParams)) {
      return;
    }

    setExtraQueryParams(newQueryParams);
  }, [queryParams, extraQueryParams]);

  const resultData = _data || [];
  const resultDataFiltered = localFilterText
    ? resultData.filter((d) =>
        Object.values(d).some((v) => (v?.includes ? v.includes(localFilterText) : false))
      )
    : resultData;

  const onExport = () => {
    setExtraQueryParams({
      export: true,
    });

    setTimeout(() => {
      setExtraQueryParams({
        export: false,
      });
    }, 1000);
  };

  const sortedRows = useMemo(() => {
    if (!sortableColumns) {
      return resultDataFiltered;
    } else {
      return [...resultDataFiltered].sort((a, b) => {
        for (const sort of sortColumns) {
          const comparator = getComparator(sort.columnKey);
          const compResult = comparator(a, b);
          if (compResult !== 0) {
            return sort.direction === 'ASC' ? compResult : -compResult;
          }
        }
        return 0;
      });
    }
  }, [resultDataFiltered, sortColumns]);

  const setFilters = (val) => {
    _setFilters((state) => {
      return { ...state, ...val };
    });
  };

  return (
    <NinjaTableContext.Provider
      value={{
        filters,
        setFilters,
        initialFilter,
        setInitialFilter,
        pagination,
        setPagination,
        setStatuses,
        setSelectedRows,
        extraQueryParams,
        setExtraQueryParams,
        allColumns,
        setColumns,
        setLocalFilterText,
        isLoading: loadOnFetching ? result.isFetching : result.isLoading,
        totalRows: result?.data?.totals,
        savedFilters,
        setSavedFilters,
        activeFilters,
        setActiveFilters,
        savedFiltersParams,
        onExport,
      }}
    >
      {prependComponent ? prependComponent : null}
      <div className={clsx('flex flex-col w-full', containerClassName)}>
        {enableFilters && (
          <div className='col-span-12'>
            <FilterComponent {...filterProps} />
          </div>
        )}
        <div className='sm:col-span-12'>
          {wrapperPrependComponent && wrapperPrependComponent}
          <NinjaTableWrapper
            withoutHeader={withoutHeader}
            disableCard={disableCard}
            title={title}
            icon={icon}
            header={header}
            datepicker={datepicker}
            resultData={resultData}
            customizationId={customizationId}
            exportData={exportData}
            customize={customize}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            onCustomizationUpdate={onCustomizationUpdate}
            customizationEdit={customizationEdit}
            removeColumns={removeColumns}
            datePickerProps={{
              onChange: setDates,
              value: dates_redux,
              changeGlobal: false,
              showExpandDays: showExpandDays,
              date_key: date_key,
            }}
            id={id}
            {...wrapperProps}
            {...rest}
          >
            {tablePrependComponent && tablePrependComponent}
            {selectRow && (
              <SelectorComponent
                pagination={pagination}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                setStatuses={setStatuses}
                resultData={resultData}
                filters={filters}
                {...selectorProps}
              />
            )}
            <NinjaTableGrid
              loading={loadOnFetching ? result.isFetching : result.isLoading}
              summaryRows={result.data?.totals || []}
              resultData={resultData}
              rows={sortedRows}
              sortColumns={sortColumns}
              onSortColumnsChange={onSortColumnsChange}
              wrapper={columnProps.DraggableComponent}
              {...columnProps}
              selectedRows={selectedRows}
              onSelectedRowsChange={setSelectedRows}
              maxHeight={maxHeight}
              rowHeight={rowHeight}
              defaultColumnOptions={{
                sortable: sortable,
              }}
              {...tableProps}
            />
            {!disableFooter && <NinjaTableFooter setPerPage={emitPerPage} {...footerProps} />}
          </NinjaTableWrapper>
        </div>
      </div>
    </NinjaTableContext.Provider>
  );
};

export const useNinjaTable = () => useContext(NinjaTableContext);

export default NinjaTable;
