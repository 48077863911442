import React from 'react';
import { Button, makeStyles, IconButton } from '@material-ui/core';
import { mdiFilterOutline, mdiPlus, mdiTrashCanOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { useNinjaTable } from '../NinjaTable';
import { useListFiltersQuery } from 'store/redux/apis/workspace';
import ninjaApi from 'store/redux/apis';
import { Popover } from 'antd';
import { useDeleteFilterMutation, useSaveFilterMutation } from 'store/redux/apis/workspace';

const SavedFilter = ({ handleRedirect }) => {
  const classes = useStyles();
  const { filters, setFilters, savedFiltersParams, initialFilter } = useNinjaTable();
  const [getGetSavedFilterQuery] = ninjaApi.useLazyGetSavedFilterQuery();
  const { data: savedFilters = [] } = useListFiltersQuery(savedFiltersParams);
  const [deleteFilter] = useDeleteFilterMutation();

  const toggleFilter = async (id) => {
    let newFilters = {};
    if (id === filters?._filterId) {
      setFilters({ ...initialFilter, _filterId: null });
    } else {
      const res = await getGetSavedFilterQuery({ filter_id: id }).unwrap();
      newFilters = { ...res.filters, _filterId: res.id };
    }
    setFilters(newFilters);
  };

  const deleteSavedFilter = async (filterId) => {
    await deleteFilter({ filter_id: filterId }).unwrap();
  };

  return (
    <div className='min-h-96 overflow-y-scroll w-full'>
      <div className='grid gap-2'>
        {Array.isArray(savedFilters?.data)
          ? // to do active saved filter fields value after new filters component
            savedFilters?.data?.map((f) => (
              <div className={classes.root}>
                <Button
                  size='small'
                  key={f.id}
                  color={filters?._filterId === f.id ? 'secondary' : 'default'}
                  onClick={() => {
                    toggleFilter(f.id);
                  }}
                  variant='outlined'
                  className={
                    filters?._filterId === f.id ? 'text-orange w-full' : 'text-gray w-full'
                  }
                >
                  <span
                    className={
                      filters?._filterId === f.id
                        ? 'text-orange font-medium'
                        : 'text-gray font-normal'
                    }
                  >
                    {f.name}
                  </span>
                  <Icon
                    size={0.8}
                    path={mdiFilterOutline}
                    className={filters?._filterId === f.id ? 'text-orange mr-2' : 'text-gray mr-2'}
                  />
                </Button>
                <Button
                  variant='outlined'
                  color='default'
                  size='small'
                  onClick={() => deleteSavedFilter(f.id)}
                >
                  <Icon path={mdiTrashCanOutline} className={classes.deleteIcon} size={0.8} />
                </Button>
              </div>
            ))
          : null}
      </div>
      {/* <div className={classes.addNew}>
        <Button
          className='flex items-center gap-2'
          variant='outlined'
          size='small'
          onClick={handleRedirect}
        >
          <Icon path={mdiPlus} size={0.5} />
          <span className='!text-xs !font-normal'>Add New</span>
        </Button>
      </div> */}
    </div>
  );
};
export default SavedFilter;

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    '& .MuiButton-label': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  addNew: {
    padding: '0px',
    display: 'flex',
    justifyContent: 'end',
    marginTop: 5,
  },
  deleteIcon: {
    color: '#808080',
    margin: 'auto',
  },
}));
