import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import useRules from 'store/RulesContext';
import { useCreateRuleMutation, useUpdateRuleMutation } from 'store/redux/apis/rules.api';
import FilterQueryBuilder from 'modules/RulesModule/components/FilterQueryBuilder';
import { Drawer, Select, Input, Radio, Form, Button } from 'antd';
import useWorkspace from 'store/redux/hooks/useWorkspace';
import { useRuleFieldsQuery } from 'store/redux/apis/rules.api';
import useAuth from 'store/redux/hooks/useAuth';
import useWorkspaces from 'store/redux/hooks/useWorkspaces';
import NetworkAccountSelect from 'components/Selects/NetworkAccountSelect';
import CampaignSelect from 'components/Selects/CampaignSelect';
import FormItem from 'components/form/fields/FormItem';

const RuleModal = () => {
  const { user } = useAuth();
  const { workspaces } = useWorkspaces();
  const { workspace } = useWorkspace();
  const { ruleData, openModal, setOpenModal, setRuleData } = useRules();
  const [createRule] = useCreateRuleMutation();
  const [updateRule] = useUpdateRuleMutation();
  const { data: fields } = useRuleFieldsQuery({});
  const [required, setRequired] = useState();

  const handleCreateRule = () => {
    const hasValidConditions = form.values.filters.conditions.some(
      (condition) => Object.keys(condition[0]).length > 0
    );
    if (!hasValidConditions) {
      setRequired(true);
    } else {
      const mutation = ruleData ? updateRule : createRule;
      mutation({ ...form.values, rule_id: ruleData?.id })
        .unwrap()
        .then(handleClose);
      setRequired(false);
    }
  };

  const form = useFormik({
    initialValues: {
      ruleable_id: workspace.id,
      owner_workspace_id: workspace.id,
      user_id: user.id,
      ruleable_type: null,
      name: '',
      asset: null,
      ids: [],
      filters: { type: 'and', conditions: [[{}]] },
      action: {},
    },
    onSubmit: handleCreateRule,
  });

  useEffect(() => {
    if (ruleData) {
      form.setValues({
        ...form.values,
        ...ruleData,
        action: ruleData.action,
        filters: ruleData.dynamic_filter.filters,
      });
    }
  }, [ruleData]);

  // useEffect(() => {
  //   form.setFieldValue('ruleable_id', workspace.id);
  //   form.setFieldValue('owner_workspace_id', workspace.id);
  // }, [workspace]);

  const handleClose = () => {
    setOpenModal(false);
    setRuleData(null);
    form.resetForm();
  };

  const title = ruleData ? 'Update rule' : 'Create rule';

  return (
    <Drawer
      title='Create Rule'
      placement='right'
      size='large'
      open={openModal}
      onClose={handleClose}
    >
      <Form layout='vertical' onFinish={handleCreateRule}>
        <FormItem label='Rule Name' required={true} message='Enter rule name'>
          <Input
            className='w-full h-12 text-sm font-normal'
            onChange={(e) => form.setFieldValue('name', e.target.value)}
            value={form.values.name}
          />
        </FormItem>
        <FormItem label='Choose Rule Type' required={true} message='Please choose a rule type'>
          <Select
            className='w-full h-12'
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            onChange={(v) => {
              form.setFieldValue('ruleable_type', v);
            }}
            value={form.values.ruleable_type}
          >
            {fields?.data?.ruleables.map((item, index) => (
              <Select.Option key={index} value={item.key}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </FormItem>
        {form.values.ruleable_type === 'NetworkAccount' && (
          <FormItem
            label='Choose Network Account'
            required={true}
            message='Please Choose Network Account'
          >
            <NetworkAccountSelect
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              className='w-full h-12'
              value={form.values.ids}
              onChange={(val) => {
                form.setFieldValue('ids', val);
              }}
            />
          </FormItem>
        )}
        {form.values.ruleable_type === 'NetworkCampaign' && (
          <FormItem label='Select Campaign' required={true} message='Please Select Campaign'>
            <CampaignSelect
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              className='w-full h-12'
              multiple
              value={form.values.ids}
              onChange={(val) => {
                form.setFieldValue('ids', val);
              }}
            />
          </FormItem>
        )}
        {form.values.ruleable_type === 'Workspace' && (
          <FormItem label='Choose Workspace' required={true} message='Please Choose Workspace'>
            <Select
              className='w-full h-12'
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              onChange={(v) => form.setFieldValue('ids', v)}
              value={form.values.ids}
            >
              {workspaces.map((item, index) => (
                <Select.Option key={index} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
        )}
        <FormItem label='Choose Asset' required={true} message='Asset type is required'>
          <Select
            className='w-full h-12'
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            onChange={(v) => {
              form.setFieldValue('asset', v);
            }}
            value={form.values.asset}
          >
            {fields?.data?.assets.map((item, index) => (
              <Select.Option key={index} value={item.key}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </FormItem>
        <FormItem label='Choose Action' required={true} message='Action type is required'>
          <Select
            className='w-full h-12'
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            onChange={(v, options) => {
              form.setFieldValue('action.command', v);
              form.setFieldValue('action.key', v);
              form.setFieldValue('action.label', options.children);
            }}
            value={form.values.action.label}
          >
            {fields?.data?.actions.map((item, index) => (
              <Select.Option key={index} value={item.key}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </FormItem>
        {form.values.action.key === 'CHANGE_BUDGET' && (
          <FormItem required={true} message='Enter Value'>
            <Input
              className='w-full h-12 text-sm font-normal'
              placeholder='Enter Value'
              onChange={(e) => form.setFieldValue('action.value', e.target.value)}
              value={form.values.action.value}
            />
          </FormItem>
        )}
        {form.values.action.key === 'CHANGE_STATUS' && (
          <FormItem required={true} message='choose Status'>
            <Radio.Group
              className='h-10 sm:h-full flex'
              onChange={(e) => form.setFieldValue('action.value', e.target.value)}
              value={form.values.action.value}
            >
              <Radio value={1} className='!font-normal h-full flex items-center'>
                Active
              </Radio>
              <Radio value={0} className='!font-normal h-full flex items-center'>
                Pause
              </Radio>
            </Radio.Group>
          </FormItem>
        )}
        <div className='my-6'>
          <FilterQueryBuilder
            error={required}
            data={form.values.filters.conditions}
            fields={fields?.data}
            onChange={(conditions) => {
              form.setFieldValue('filters.conditions', conditions);
            }}
          />
          <div
            className={`text-xs font-normal text-red transition-all duration-300 mt-4 ${
              required ? 'opacity-100' : 'opacity-0'
            } ${required ? 'translate-y-3' : '-translate-y-0'}`}
          >
            {required && 'Please add at least one filter condition to create or update the rule'}
          </div>
        </div>
        <FormItem>
          <Button type='primary' htmlType='submit'>
            {title}
          </Button>
        </FormItem>
      </Form>
    </Drawer>
  );
};

export default RuleModal;
