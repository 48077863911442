import { Button, FormControlLabel, makeStyles } from '@material-ui/core';
import Checkbox from 'components/switches/Checkbox';
import React, { useEffect, useState } from 'react';
import useLocalStorage from '@ninja/hooks/useLocalStorage';

const ColumnsCustomize = ({
  customizationId,
  onUpdate = () => {},
  columns,
  customizationEdit,
  removeColumns = [],
}) => {
  const classes = useStyles();
  const [tmpColumns, setTmpColumns] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [localColumns, setLocalColumns] = useLocalStorage({ key: customizationId });

  const updatedObject = { ...tmpColumns };

  for (const columnId of removeColumns) {
    if (updatedObject[columnId] && customizationEdit !== null) {
      updatedObject[columnId] = { ...updatedObject[columnId], hidden: true };
    }
  }

  useEffect(() => {
    const tempCustomColumns = {
      ...localColumns,
    };

    columns.forEach((c) => {
      if (!tempCustomColumns[c.key]) {
        tempCustomColumns[c.key] = { hidden: false, name: extractName(c) };
      }
    });

    setLocalColumns(tempCustomColumns);
  }, [columns]);

  useEffect(() => {
    setTmpColumns(localColumns);
    onUpdate(updatedObject);
  }, [localColumns, customizationEdit]);

  const onCustomizeSave = () => {
    setLocalColumns(tmpColumns);
    onUpdate(updatedObject);
  };

  const handleChange = (columnId, checked) => {
    setTmpColumns((ps) => ({
      ...ps,
      [columnId]: {
        ...ps[columnId],
        hidden: !checked,
      },
    }));
  };

  const handleSelectAllChange = (checked) => {
    let colIds = Object.keys(updatedObject);
    setSelectAll(checked);

    colIds.forEach((columnId) => handleChange(columnId, checked));
  };

  const extractName = (column) => {
    if (typeof column.name === 'string') {
      return column.name;
    } else if (column.name && column.name.props && column.name.props.name) {
      return column.name.props.name;
    }
    return 'Unknown';
  };

  return (
    <div className='w-full'>
      <div className={classes.fieldsContainer}>
        <FormControlLabel
          control={
            <Checkbox
              variant='outlined'
              text='Select All'
              checked={selectAll}
              onChange={(e) => {
                handleSelectAllChange(e.target.checked);
              }}
            />
          }
          label='Select All'
        />
        {Object.entries(updatedObject).map(([columnId, column]) => (
          <div className={`align-center grid ${classes.field}`} key={columnId}>
            <FormControlLabel
              control={
                <Checkbox
                  variant='outlined'
                  text={extractName(column)}
                  checked={!column.hidden}
                  onChange={(e) => {
                    handleChange(columnId, e.target.checked);
                  }}
                />
              }
              label={extractName(column)}
            />
          </div>
        ))}
      </div>
      <div className={`grid justify-end ${classes.footer}`}>
        <Button size='small' style={{ fontWeight: 400 }} onClick={onCustomizeSave}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default ColumnsCustomize;

const useStyles = makeStyles(() => ({
  fieldsContainer: {
    maxHeight: 200,
    overflowY: 'auto',
    padding: 10,
    width: '100%',
  },
  footer: {
    display: 'flex',
    paddingTop: 10,
    borderTop: '1px solid #80808047',
  },
  field: {
    width: '100%',
  },
}));
