import { ListItemIcon, ListItemText, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { Fragment } from 'react';

const Communuty = (props) => {
  const classes = useStyles();
  const { sidebarOpen, url, icon, text, disabled, ...rest } = props;

  let Component = Fragment,
    componentProps = {};

  if (!sidebarOpen) {
    Component = Tooltip;
    componentProps = { placement: 'right-center', title: text, enterDelay: 100 };
  }

  return (
    <Component {...componentProps}>
      <div
        className={clsx(classes.container, {
          [classes.sidebarOpened]: sidebarOpen,
          [classes.disabledClass]: disabled,
        })}
      >
        <a href={!disabled && url} className={classes.listItem} disabled={disabled} {...rest}>
          {icon ? <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon> : null}
          <ListItemText
            className={classes.listItemText}
            classes={{ primary: classes.listItemText }}
          >
            {text}
          </ListItemText>
        </a>
      </div>
    </Component>
  );
};

export default Communuty;

export const useStyles = makeStyles((theme) => ({
  container: {},
  listItem: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    padding: theme.spacing(1, 0),
    background: '#2f3641',
    margin: 0,
    '&:hover *': {
      color: '#fff',
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  listItemText: {
    fontFamily: 'fregular',
    fontWeight: 500,
    fontSize: 14,
    color: '#f1f5f8',
  },
  listItemIcon: {
    padding: '0px 24px',
    minWidth: 'unset',
    color: '#f1f5f8',
    transition: '0.5s ease-out',
  },
  sidebarOpened: {
    '& $listItemIcon': {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    '& $listItem': {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      borderRadius: '6px',
    },
  },
  disabledClass: {
    cursor: 'not-allowed',
  },
}));
